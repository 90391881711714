import React from "react";
import {Avatar, Progress} from "antd";
import "./About.scss";
import {Typography} from "antd";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCode} from "@fortawesome/free-solid-svg-icons/faFileCode";
import {faUsers} from "@fortawesome/free-solid-svg-icons/faUsers";

const {Title, Text} = Typography;

function About(): React.ReactElement {
	return (
		<section className={"About"}>
			<header className={"header"}>
				<Avatar
					size={200}
					src={"https://storage.googleapis.com/laforgesplayground.appspot.com/public-images/ryan-laforge.jpg"}
				/>
				<Title className={"title"} level={1}>Ryan LaForge</Title>
				<Text className={"subtitle"}>Full-Stack Software Engineer</Text>
			</header>
			<div className={"headline-section"}>
				<article className={"headline"}>
					<header>
						<Title className={"headline-header"} level={3}>Software Engineer</Title>
					</header>
					<FontAwesomeIcon className={"headline-icon"} icon={faFileCode}
									 style={{fontSize: "48px", color: "#1890ff"}}/>
					<Text>Graduated in 2015 from the University of Saskatchewan with a Bachelor's degree in Computer Science.</Text>
				</article>
				<article className={"headline"}>
					<header>
						<Title className={"headline-header"} level={3}>Full-Stack</Title>
					</header>
					<FontAwesomeIcon className={"headline-icon"} icon={faLayerGroup}
									 style={{fontSize: "48px", color: "#1890ff"}}/>
					<Text>Full-Stack engineer who can develop applications front-to-back</Text>
				</article>
				<article className={"headline"}>
					<header>
						<Title className={"headline-header"} level={3}>CTO & Co-Founder</Title>
					</header>
					<FontAwesomeIcon className={"headline-icon"} icon={faUsers}
									 style={{fontSize: "48px", color: "#1890ff"}}/>
					<Text>
						Co-Founder and CTO of the <a href={"https://canadianhomebuyersguide.ca"}>Canadian Home Buyers' Guide</a>.
					</Text>
				</article>
			</div>
				<section className={"skills-section"}>
					<header>
						<Title level={3} className={"skills-header"}>Technical skills</Title>
					</header>
					<div className={"skills-aside"}>
						<div className={"skills-bar-section"}>
							<Progress percent={40} format={(() => "HTML / CSS")} trailColor={"#bae7ff"}/>
							<Progress percent={50} format={() => "Python"} trailColor={"#bae7ff"}/>
							<Progress percent={70} format={(() => "Golang")} trailColor={"#bae7ff"}/>
							<Progress percent={70} format={(() => "Typescript")} trailColor={"#bae7ff"}/>
						</div>
						<div className={"skills-bar-section"}>
							<Progress percent={50} format={(() => "Vue")} trailColor={"#bae7ff"}/>
							<Progress percent={65} format={(() => "React")} trailColor={"#bae7ff"}/>
							<Progress percent={90} format={() => "Angular"} trailColor={"#bae7ff"}/>
						</div>
						<div className={"skills-bar-section"}>
							<Progress percent={50} format={(() => "Kubernetes")} trailColor={"#bae7ff"}/>
							<Progress percent={60} format={(() => "AWS")} trailColor={"#bae7ff"}/>
							<Progress percent={80} format={(() => "Google Cloud")} trailColor={"#bae7ff"}/>
							<Progress percent={90} format={(() => "Firebase")} trailColor={"#bae7ff"}/>
						</div>
						<div className={"skills-bar-section"}>
							<Progress percent={60} format={(() => "New SQL")} trailColor={"#bae7ff"}/>
							<Progress percent={80} format={(() => "SQL")} trailColor={"#bae7ff"}/>
							<Progress percent={90} format={(() => "NoSQL")} trailColor={"#bae7ff"}/>
						</div>
					</div>
				</section>
		</section>
	)
}


export default About;
