import React, {useState} from 'react';
import './App.scss';
import {Layout, Menu} from 'antd';
import {SelectParam} from "antd/lib/menu";
import About from "./About";
import Resume from "./Resume";
import Projects from "./Projects";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF} from "@fortawesome/free-brands-svg-icons/faFacebookF";
import {faGithubAlt} from "@fortawesome/free-brands-svg-icons/faGithubAlt";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons/faLinkedinIn";

const {Header, Content, Footer} = Layout;

const headerHeight = "64px";
const footerHeight = "54px";
const contentMinHeight = `calc(99vh - ${headerHeight} - ${footerHeight})`;
type PageSelection = "About" | "Resume" | "Projects";

function App(): React.ReactElement {
	const [page, setPage] = useState<PageSelection>("About");
	return (
		<Layout className={"App"}>
			<Header className={"header menu"} style={{minHeight: headerHeight}}>
				<Menu
					className={"menu"}
					theme={"dark"}
					mode={"horizontal"}
					style={{lineHeight: headerHeight}}
					defaultSelectedKeys={[page]}
					onSelect={({key}: SelectParam) => setPage(key as PageSelection)}
				>
					<Menu.Item key="About">About</Menu.Item>
					<Menu.Item key="Resume">Resume</Menu.Item>
					<Menu.Item key="Projects">Projects</Menu.Item>
				</Menu>
			</Header>
			<Content style={{minHeight: contentMinHeight}}>
				{page === "About" && <About/>}
				{page === "Resume" && <Resume/>}
				{page === "Projects" && <Projects/>}
			</Content>
			<Footer className={"footer"} style={{height: footerHeight}}>
				<Menu className={"icon-row menu"} mode={"horizontal"}>
					<Menu.Item key={"Facebook"}>
						<a href={"https://www.facebook.com/ryan.laforge.18"}>
							<FontAwesomeIcon icon={faFacebookF} color={"#FFF"} size={"2x"}/>
						</a>
					</Menu.Item>

					<Menu.Item key={"GitHub"}>
						<a href={"https://github.com/r-LaForge"}>
							<FontAwesomeIcon icon={faGithubAlt} color={"#FFF"} size={"2x"}/>
						</a>
					</Menu.Item>
					<Menu.Item key={"LinkedIn"}>
						<a href={"https://www.linkedin.com/in/ryan-laforge-1b44aa105/"}>
							<FontAwesomeIcon icon={faLinkedinIn} color={"#FFF"} size={"2x"}/>
						</a>
					</Menu.Item>
				</Menu>
			</Footer>
		</Layout>
	);
}

export default App;
